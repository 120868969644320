<template>
  <PageHeader :title="title" />
  
  <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
    <button @click="infoblockShow = !infoblockShow" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
  </div>
  
  <!-- 
    Інфо-блоки 
    Є перевірка:
      * Якщо існують і прописані інфоблоки
  -->
  <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- 
      Компонент таблиці
      - :dataParams - параметри до кнопки фільтру
      - :objParams - параметри для фільтрації таблиці 
      - @search - подія, яка спрацьовує при пошуку у таблиці
      - :columns - конфігурація стовпців таблиці
      - :rows - масив з даними рядків таблиці
      - :pages - об'єкт із даними про пагінацію таблиці
      - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
      - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
      - @create - подія, яка спрацьовує при створенні нового співробітника
      - @open - подія, яка спрацьовує при відкритті картки співробітника
      - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
      - @clearFilter - подія, яка спрацьовує при очищенні фільтрів таблиці
      - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
      - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
      - :showContxtMenu - параметр, який вказує, чи відображати контекстне меню
      - @showMenu - подія, яка викликає контекстне меню
    -->
  <tablecustom 
    :dataParams="dataParams"
    :objParams="objParams"
    @search="searchB" 
    :columns="columns" 
    :rows="rows"
    :pages="objPages"
    :showpag="true" 
    :createdform="this.perms[201] || this.perms[2000]" 
    @create="modalCreate = true"  
    @open="open" 
    @changeFilter="changeFilter" 
    @clearFilter="clearFilter" 
    @getdata="getdata"
    @changelimit="changelimit"
    :showContxtMenu="true"
    @showMenu="showContextMenu"
  />
    
  <!-- 
    Картка клієнта 
      Є перевірка:
        * Змінна testshowModalclient
      - :objcard - картка клієнта
      
  -->
  <viewRegist 
    v-if="testshowModalclient == true" 
    :objcard="this.objClient"
  ></viewRegist>
  <!-- картка клієнта -->

  <!-- 
    Картка задачі
    Відкривається картка через цикл v-for
    - @close - закриття картки завдання
    - :shortapplic - дані по тасці
    - :ifPrevopen - стрілочка "вліво" у вікні таски
    - :ifNextopen - стрілочка "вправо" у вікні таски
    - @prevOpen - подія, що спрацювує при натискані стрілочки "вліво"
    - @nextOpen - подія, що спрацювує при натискані стрілочки "вправо"
    - @edit - подія, що спрацювує при редагувані
  -->
  <viewtask 
    v-for="form in taskForms"
    @close="closeView" 
    :shortapplic="cardApplic" 
    :ifPrevopen="ifPrevopen" 
    :ifNextopen="ifNextopen" 
    @getdata="getdata"
    @prevOpen="prevOpen" 
    @nextOpen="nextOpen"
    @edit = "edit"
  ></viewtask>
  
  <!--
    Cтворення задачі:
    Є перевірка:
      * Змінна modalCreate
    @close - подія, що спрацювує при закриті вікна створення 
    @open - подія відкриття таски
    @closelast - подія відкриття таски після створення і закриття старового варіанту
    :perm - "дозвіл" на редагування
    :obj - таска, котру треба редагувати
    :copyItem - параметри карти для дублікату
  -->
  <createdtask 
    v-if="modalCreate" 
    @close="modalClose(); this.permOnEdit = false"
    @open = "openTask"
    @closelast = 'closelast'
    :perm = "this.permOnEdit"
    :obj = "this.obj"
    :copyItem="copyItem"
  ></createdtask>

  <!-- Контекстне меню -->
  <my-context-menu 
    ref="contextMenu" 
    :btn="btnContextMenu"
    @select="switchCntMenu"
  />

  <!-- <dialogChoose
    :tasks = 'this.tasks'
    v-if="showmodal == true"
    @createNew = "modalCreate = true"
    @close = 'showmodal = false'
    @continue = 'modalCreate = true'
  ></dialogChoose>  -->
</template>

<script>
import viewRegist from '@/views/accounts/allAccounts/viewnew/index'
import PageHeader from "@/components/page-header";
import infoblocks from '@/components/info/blocks'
import tablecustom from '@/components/globaltable/index';
import viewtask from '@/views/tasks/view/viewtask.vue'
import createdtask from '@/views/tasks/createtask'
import { mutateTypeTask, mutateStatusTask, mutatePriorityTicket } from '@/usabilityScripts/globalMutate'
import { Tasks, Archive, Logs } from '@/API.js';
import { storeS } from "@/store";
import MyContextMenu from '@/components/contextMenu/index.vue'; // компонент контекст-меню
import dialogChoose from "../../components/globaltable/actions/dialogChoose.vue";
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js'

let apiServe = new Tasks();
let apiArchive = new Archive();
let apiLogs = new Logs();

export default {
  components: {
    viewRegist,
    dialogChoose,
    PageHeader,
    createdtask,
    viewtask,
    tablecustom,
    infoblocks,
    MyContextMenu
  },
data() {
  return {
    testshowModalclient: false,
    taskForms: 0,
    permOnEdit: false,
    obj: '',
    tasks: [],
    storage: '',
    showpag: false,
    title: this.$t('all_tasks'),
    objPages: {},
    modalCreate: false,
    infoblockShow: false,
    showModal: false,
    dataParams: {
        status: true,
        page: "tasks"
    },
    objParams:{
        page: '1',
        pagelimit: '10',
        search: '',
        status: '',
        pr1: '',
        pr2: '',
        priority: '',
        workerId: '',
        taskName: ''
    },
    columns: [
      {
        name: "ID",
        text: "taskId",
        align: "left",
        status: true,
      },
      {
        name: this.$t('Task'),
        text: "taskName",
        align: "left",
        status: true,
        mutate: (item) => this.mutateType(item)
      },
      // {
      //   name: this.$t('inJobWorker'),
      //   text: "inJob",
      //   align: "left",
      //   status: true,
      //   mutate: (item) => item.workerName
      // },
      {
        name: this.$t("Status"),
        text: "status",
        align: "left",
        status: true,
        mutate: (item, obj) => this.mutateStatus(item, obj).name,
        mutateClass: (item, obj) => this.mutateStatus(item, obj).color
      },
      {
        name: this.$t("priority"),
        text: "priority",
        align: "right",
        status: true,
        mutate: (item) => this.mutatePriority(item).name,
        mutateClass: (item) => this.mutatePriority(item).color
      },
      {
        name: this.$t("phone_num"),
        text: "userPhone",
        align: "right",
        status: true,
      },
      {
        name: this.$t("added"),
        text: "created",
        align: "right",
        status: true,
        mutate: (item) => item.workerName
      },
      {
        name: this.$t("createDate"),
        text: "created",
        align: "right",
        status: true,
        mutate: (item) => item.datetime
      }
    ],
    rows: [],
    tasksArr: [], //массив задач
    cardApplic: {},
    ifNextopen: "",
    ifPrevopen: "",
    information: [
      {
          title: this.$t('Total'),
          value: "0",
          icon: "las la-file-invoice",
          color: "success"
      },
      {
          title: this.$t('new'),
          value: "0",
          icon: "las la-file-invoice",
          color: "success"
      },
      {
          title: this.$t('waiting_confirmation'),
          value: "0",
          icon: "las la-file-invoice",
          color: "danger"
      },
      {
          title: this.$t('waiting_completed'),
          value: "0",
          icon: "las la-file-invoice",
          color: "info"
      }
    ],
    isUpdateBlocked: false, // прапорець для блокування
    btnContextMenu: [ // Параметри для контекстного меню
        { 
            title: this.$t('Open'),
            icon: 'bx bxs-door-open',
            color: 'info',
            value: 'open',
            status: true,
            perms: null
        },
        { 
            title: this.$t('CreateaСopy'),
            icon: 'bx bx-copy-alt',
            color: 'info',
            value: 'createСopy',
            status: true,
            perms: null
        },
        // { 
        //     title: this.$t('Log'),
        //     icon: 'bx bxs-info-circle',
        //     color: 'info',
        //     value: 'Log',
        //     status: true,
        //     perms: 2000
        // },
    ],
    copyItem: {
      status: false,
      data: {}
    } // параметри картки для дублікату завдання
  };
},
created() {
  this.getdata();
},
methods: {
  // закрити модалку перегляду, йде перевірка на довжину, щоб не вибивало помилку
  closeView() {
    this.isUpdateBlocked = true
    if(this.taskForms > 0) {
      this.taskForms--
    }
    // очистка інфи в сторі
    storeS.checkModal.type = ''; 
    storeS.checkModal.id = ''; 
    // очистка інфи в сторі
    this.getdata(this.objParams.page)
  },
  modalClose(){
    // закриття модалки створення
    this.modalCreate = false;
    this.copyItem = {
      status: false,
      data: {}
    };
    this.getdata();
  },
  closelast() {
    // закриття минулої
    this.taskForms--
  },
  openTask(e) {
    // відкриття після створення
    this.modalCreate = false // закривається модалка створення
    this.cardApplic = e // передача даних в пропс
    this.taskForms++ // відкриття перегляду таски
  },
  changelimit: function(limit){
    // змінити ліміт пагінації
    this.objParams.pagelimit = limit;
    this.getdata();
  },
  getdata(page){
    // отримання тасок
    this.objParams.page = page != undefined ? page : '1';
    apiServe.getTasks(this.objParams).then(result => {
      if(result.status === 'done') {
        this.objPages = result.data;
        this.rows = result.data.items;

        this.rows.forEach(el => {
          if(new Date(el.executionDatetime) < new Date() && el.executionDatetime != false && el.status != 'confirmed'){
            apiServe.refreshStatusTask(el.taskId, {status: "confirmed"}).then(result => {
              if(result.status == 'done') {
                this.$toast.success(this.$t('success'))
                this.getdata()
              }
            })
          }
        })

        // заповнення інфоблоків
        this.information[0].value = result.data.total;
        this.information[1].value = result.data.countNew;
        this.information[2].value = result.data.countWaitConfirm;
        this.information[3].value = result.data.countWaitCompleted;
        // заповнення інфоблоків

        this.isUpdateBlocked = false
      } else {
        this.$toast.error(this.$t('error') + ` #200`);

        this.isUpdateBlocked = false

        // Підготовка і відправка логів в ТГ при помилках
        var errorText = 'Помилка отримання тасок'; 
        var statusRequest = result.status;
        var api = 'getTasks';
        var fileRequest = 'src/views/tasks/all.vue';
        var params = this.objParams;
        var response = result

        // Викликаємо функцію для відправки повідомлення в телеграм
        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error)); 
      }
    })
  },
  open: function(e){
    // відкриття завдання 
    var id = e.taskId ? e.taskId : e; // перевірка вхідного параметру
    this.ifPrevopen = id == this.rows[0].taskId ? false : true;
    this.ifNextopen = id == this.rows[this.rows.length-1].taskId ? false : true;

    apiServe.getTask(id).then(result => {
      if(result.status == 'done'){
        this.cardApplic = result.data;
        this.taskForms++
      } else if (result.status == 'error' && result.error[0] == 'task not found') {
        this.$toast.error(this.$t('taskNotFound'))
      } else {
        this.$toast.error(this.$t('err'))

        var errorText = 'Помилка відкриття заявки завдання'; 
        var statusRequest = result.status;
        var api = 'getTask';
        var fileRequest = 'src/views/tasks/all.vue';
        var params = id;
        var response = result

        // Викликаємо функцію для відправки повідомлення в телеграм
        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error)); 
      }
    })
  },
  prevOpen(e){
    // функція відкриття попередньою таски
    var current_id = e;
    var prev_id = ""; 

    for(var value in this.rows){
      if(this.rows[value].taskId == current_id){
        prev_id = this.rows[Number(value)-Number('1')].taskId;
      }
    }
    this.open(prev_id);
  },
  nextOpen(e){
    // відкриття наступної таски
    var current_id = e;
    var next_id = "";

    for(var value in this.rows){
      if(this.rows[value].taskId == current_id){
        next_id = this.rows[Number(value)+Number('1')].taskId;
      }
    }
    this.open(next_id);
  },
  edit(e) {
    // функція редагування
    this.permOnEdit = true; // пермс на редагування
    this.obj = e; // дані, що треба редагувати
    this.modalCreate = true; // відкриття модалки створення
  },
  searchB(e){
    // пошук по завданням
    this.objParams.search = e
    this.getdata();
  },
  changeFilter(name, value){
    // обробка фільтрів, що прийшли. Зміна  фільтрів
    if(!name.type && value != undefined){
      if(name == 'pr'){ // перевірка, чи є фільтр датою
        this.objParams['pr1'] = value[0];
        this.objParams['pr2'] = value[1];
      } else {
        this.objParams[name] = value;
      }
      this.getdata();
    }
  },
  showContextMenu(event, item) {
      // Метод, який викликається при правому кліку на елементі, передаючи подію та об'єкт item

      // Викликаємо метод показу контекстного меню після оновлення DOM
      this.$nextTick(() => {
          // Виклик методу показу контекстного меню у компоненті contextMenu,
          // передаючи подію та об'єкт item
          this.$refs.contextMenu.showContextMenu(event, item);
      });
  },
  switchCntMenu(e, item){
      // Виконуємо обрану дію з контекстного меню
      switch (e) {
          case 'open':
              // Відкрити компанію
              this.open(item)
              break;
          case 'createСopy':
              // Відкрити компанію
              this.copyItem = {
                status: true,
                data: item
              };
              this.modalCreate = true
              break;
          case 'Log':
              // Відкрити компанію
              var ids = [];
              ids.push(item.taskId)

              var params = {
                page: '1',
                pagelimit: '200',
                date1: '2024-01-01',
                date2: this.currentDate(),
                ids: ids[0] == "" ? "" : JSON.stringify(ids)
              }
              
              apiLogs.getLogs(params).then(result => {
                console.log('res', result);
              })
              break;
          
          default:
              break;
      }
  },
  currentDate() {
    var date = new Date();
    var dateStr =
    date.getFullYear() + "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
    ("00" + (date.getDate())).slice(-2);
    return dateStr
  },
  mutateType(e){
    return mutateTypeTask(e)
  },
  mutateStatus(e, obj){
    return mutateStatusTask(e, obj)
  },
  mutatePriority(e){
    return mutatePriorityTicket(e)
  },
  clearFilter(){
    this.objParams = {
      page: this.objParams.page,
      pagelimit: this.objParams.pagelimit,
      search: '',
      status: '',
      pr1: '',
      pr2: '',
      priority: '',
      workerId: '',
      taskName: ''
    }
    this.getdata()
  }
},
computed: {
  user() {
    return storeS.userbase
  },
  perms() {
    return storeS.perms
  },
  checks() {
    return storeS.checks
  },
},
mounted() {
  this.eventBus.off('saveTask');
  this.eventBus.on('saveTask', (status) => {
    if (status == true && !this.isUpdateBlocked) {
      this.getdata();
    }
  })
}
};
</script>

<style scoped>
.tr_etner:hover {
cursor: pointer;
background: #f3f3f9;
}
</style>
